// https://testing-core.deploy.kuarasoftware.es/

export const API = {
  editionxsubject: {
    list: `/editionxsubject`,
    createPreenrollment: `/createPreenrollment`,
    getPreenrollment: `/getPreenrollment`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  tests: {
    list: `/test`,
  },
  students: {
    create: `/student`,
    edit: `/student/`,
    delete: `/student/`,
    list: `/student`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
    student: `/student`,
  },

};
